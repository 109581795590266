export const deletable = {
    methods: {
        destroy(url) {
            return new Promise((resolve) => {
                this.$alert('¿Desea eliminar el registro?', 'Eliminar', {
                    confirmButtonText: 'Eliminar',
                    cancelButtonText: 'Cancelar',
                    type: 'warning'
                }).then(() => {
                    this.$http.delete(url)
                        .then(res => {
                            if(res.data.success) {
                                this.$message.success('Se eliminó correctamente el registro')
                                resolve()
                            }
                        })
                        .catch(error => {
                            if (error.response.status === 500) {
                                this.$message.error('Error al intentar eliminar');
                            } else {
                                console.log(error.response.data.message)
                            }
                        })
                }).catch(error => {
                    console.log(error)
                });
            })
        },
        voided(url) {
            return new Promise((resolve) => {
                this.$alert('¿Desea anular el registro?', 'Anular', {
                    confirmButtonText: 'Anular',
                    cancelButtonText: 'Cancelar',
                    type: 'warning'
                }).then(() => {
                    this.$http.get(url)
                        .then(res => {
                            if (res.data.success) {
                                this.$message.success('Se anuló correctamente el registro')
                                resolve()
                            }
                        })
                        .catch(error => {
                            if (error.response.status === 500) {
                                this.$message.error('Error al intentar anular');
                            } else {
                                console.log(error.response.data.message)
                            }
                        })
                }).catch(error => {
                    console.log(error)
                });
            })
        },
        close(url) {
            return new Promise((resolve) => {
                this.$alert('¿Desea cancelar el alquiler?', 'Cancelar', {
                    confirmButtonText: 'Cancelar alquiler',
                    cancelButtonText: 'Cerrar',
                    type: 'warning'
                }).then(() => {
                    this.$http.post(url)
                        .then(res => {
                            if(res.data.success) {
                                this.$message.success('El alquiler fue cancelado satisfactoriamente.')
                                resolve()
                            }
                        })
                        .catch(error => {
                            if (error.response.status === 500) {
                                this.$message.error('Error al intentar cancelar el alquiler.');
                            } else if(error.response.status === 403) {
                                this.$message.error('No posee permisos para realizar esta acción.');
                            } else {
                                console.log(error.response.data.message)
                            }
                        })
                }).catch(error => {
                    console.log(error)
                });
            })
        },
        checkout(url) {
            return new Promise((resolve) => {
                this.$alert('¿Desea finalizar el alquiler?', 'Finalizar', {
                    confirmButtonText: 'Finalizar',
                    cancelButtonText: 'Cerrar',
                    type: 'warning'
                }).then(() => {
                    this.$http.post(url)
                        .then(res => {
                            if(res.data.success) {
                                this.$message.success('El alquiler fue finalizado satisfactoriamente.')
                                resolve()
                            }
                        })
                        .catch(error => {
                            if (error.response.status === 500) {
                                this.$message.error('Error al intentar finalizar el alquiler.');
                            } else if(error.response.status === 403) {
                                this.$message.error('No posee permisos para realizar esta acción.');
                            } else {
                                console.log(error.response.data.message)
                            }
                        })
                }).catch(error => {
                    console.log(error)
                });
            })
        },
        cleaning(url) {
            return new Promise((resolve) => {
                this.$alert('¿Desea finalizar la limpieza de la habitación?', 'Finalizar', {
                    confirmButtonText: 'Finalizar',
                    cancelButtonText: 'Cerrar',
                    type: 'warning'
                }).then(() => {
                    this.$http.post(url)
                        .then(res => {
                            if(res.data.success) {
                                this.$message.success('La limpieza fue finalizado satisfactoriamente.')
                                resolve()
                            }
                        })
                        .catch(error => {
                            if (error.response.status === 500) {
                                this.$message.error('Error al intentar finalizar la limpieza.');
                            } else if(error.response.status === 403) {
                                this.$message.error('No posee permisos para realizar esta acción.');
                            } else {
                                console.log(error.response.data.message)
                            }
                        })
                }).catch(error => {
                    console.log(error)
                });
            })
        },
        remove(url) {
            return new Promise((resolve) => {
                this.$alert('¿Desea eliminar el registro?', 'Eliminar', {
                    confirmButtonText: 'Eliminar',
                    cancelButtonText: 'Cancelar',
                    type: 'warning'
                }).then(() => {
                    this.$http.post(url)
                        .then(res => {
                            if(res.data.success) {
                                this.$message.success('Se eliminó correctamente el registro')
                                resolve()
                            }
                        })
                        .catch(error => {
                            if (error.response.status === 500) {
                                this.$message.error('Error al intentar eliminar');
                            } else {
                                console.log(error.response.data.message)
                            }
                        })
                }).catch(error => {
                    console.log(error)
                });
            })
        },
    }
}