<template>
	<div>
		<div class="d-flex justify-space-between align-center mb-2 mt-2">
			<div class="input-wrap">
				<h5 class="mb-2">{{ title }}</h5>
			</div>
			<div class="action-btn-wrap">
				<v-btn color="accent" @click.prevent="clickCreate()">Nuevo</v-btn>
			</div>
		</div>

		<v-card color="secondary" class="pa-3">
			<v-row>
				<v-col cols="12" xl="4" lg="4" md="4" sm="6">
					<el-input
						placeholder="Número"
						v-model="search.number"
						style="width: 100%;"
						prefix-icon="el-icon-search"
						@input="getRecords"
					>
					</el-input>
				</v-col>
				<v-col cols="12" xl="4" lg="4" md="4" sm="6">
					<el-input
						placeholder="Nombre"
						v-model="search.name"
						style="width: 100%;"
						prefix-icon="el-icon-search"
						@input="getRecords"
					>
					</el-input>
				</v-col>
				<v-col cols="12" xl="4" lg="4" md="4" sm="6">
					<el-input
						placeholder="Dirección"
						v-model="search.address"
						style="width: 100%;"
						prefix-icon="el-icon-search"
						@input="getRecords"
					>
					</el-input>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12" xl="12" lg="12" md="12">
					<v-simple-table dense>
						<template v-slot:default>
							<thead>
								<tr>
									<th class="text-right">N°</th>
									<th class="text-right">NÚMERO</th>
									<th>NOMBRE O RAZÓN SOCIAL</th>
									<th>TELEFONO</th>
									<th>EMAIL</th>
									<th>F. NACIMIENTO</th>
									<th>CLIENTE INDESEABLE</th>
									<th>DESCRIPCIÓN</th>
									<th class="text-right">ACCIONES</th>
								</tr>
							</thead>
							<tbody>
								<tr
									v-for="(row, index) in records"
									:row="row"
									:index="customIndex(index)"
									:key="index"
									:class=" (row.undesirable) ? 'red--text' : '' "
								>
									<td class="text-right">{{ index + 1 }}</td>
									<td class="text-right">{{ row.number }}</td>
									<td>{{ row.name }}</td>
									<td>{{ row.telephone }}</td>
									<td>{{ row.email }}</td>
									<td>{{ row.birth_date }}</td>
									<td>{{ (row.undesirable) ? 'Si' : 'No' }}</td>
									<td>{{ row.description }}</td>
									<td class="text-right">
										<v-tooltip top>
											<template v-slot:activator="{ on }">
												<v-btn
													color="primary"
													class="ma-1"
													fab
													x-small
													dark
													@click.prevent="clickCreate(row.id)"
													v-on="on"
												>
													<v-icon>mdi-pencil</v-icon>
												</v-btn>
											</template>
											<span>Modificar</span>
										</v-tooltip>
										<v-tooltip top>
											<template v-slot:activator="{ on }">
												<v-btn
													color="accent"
													class="ma-1"
													fab
													x-small
													dark
													@click.prevent="clickDelete(row.id)"
													v-on="on"
												>
													<v-icon>mdi-delete</v-icon>
												</v-btn>
											</template>
											<span>Eliminar</span>
										</v-tooltip>
									</td>
								</tr>
							</tbody>
						</template>
					</v-simple-table>
				</v-col>
			</v-row>
			<v-pagination
				@next="getRecords"
				@previous="getRecords"
				@input="getRecords"
				:length="pagination.last_page"
				:total-visible="7"
				v-model="pagination.current_page"
				circle
				prev-icon="mdi-chevron-left"
				next-icon="mdi-chevron-right"
			></v-pagination>
		</v-card>

		<people-form :showDialog.sync="showDialog" :recordId="recordId" :type="type"> </people-form>
	</div>
</template>

<script>
import PeopleForm from './Form';
import queryString from 'query-string';
import { deletable } from 'Mixins/deletable';

export default {
	mixins: [deletable],
	components: { PeopleForm },
	data() {
		return {
			type: null,
			search: {
				number: null,
				name: null,
				address: null,
			},
			title: null,
			showDialog: false,
			resource: 'people',
			recordId: null,
			pagination: {},
			records: [],
		};
	},
	created() {
		this.type = this.$route.params.type;
		this.$eventHub.$on('reloadData', (resource) => {
			if (this.resource == resource) {
				this.getRecords();
			}
		});
	},
	
	async mounted() {
		await this.getRecords();
		if (this.type === 'customers') {
			this.title = 'Clientes';
		} else if (this.type === 'suppliers') {
			this.title = 'Proveedores';
		}
	},
	methods: {
		customIndex(index) {
			return this.pagination.per_page * (this.pagination.current_page - 1) + index + 1;
		},
		getRecords() {
			return this.$http
				.get(`/${this.resource}/${this.type}/records?${this.getQueryParameters()}`)
				.then((response) => {
					this.records = response.data.data;
					this.pagination = response.data.meta;
					this.pagination.per_page = parseInt(response.data.meta.per_page);
				});
		},
		getQueryParameters() {
			return queryString.stringify({
				page: this.pagination.current_page,
				limit: this.limit,
				...this.search,
			});
		},
		clickCreate(recordId = null) {
			this.recordId = recordId;
			this.showDialog = true;
		},
		clickDelete(id) {
			this.destroy(`/${this.resource}/${id}`).then(() =>
				this.$eventHub.$emit('reloadData', this.resource)
			);
		},
	},
};
</script>
